<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <v-alert
                    v-if="activatedUser"
                    type="success"
                  >{{this.successMessage}}</v-alert>
                  <v-alert v-else type="warning">{{this.errorMessage}}</v-alert>

                  <vs-button class="col-12" @click="goToLoginPage">Login</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      activatedUser: false,
      errorMessage: "",
      successMessage: ""
    };
  },
  components: {},
  methods: {
    init() {
      let param = this.$route.params.mailSecret;
      this.$goc.console.log(param);
      if (param) {
        this.activateUser(param);
      }
    },
    goToLoginPage() {
      this.$router.push("/pages/login").catch(() => {});
    },
    activateUser(mailSecret) {
      const _self = this;
      this.$goc.request.post(
        this.$goc.ENUMS.API.ActivateUser,
        {
          MailSecret: mailSecret
        },
        {
          then: response => {
            if (response) {
              _self.activatedUser = true;
              this.successMessage = response.Message;
            }
          },
          exception: response => {
            _self.activatedUser = false;
            this.errorMessage = response.data.Message;
          }
        }
      );
    }
  },
  mounted: function() {
    this.init();
  }
};
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
